import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(
      () => import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(
      () => import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(
      () => import("views/auth-views/authentication/forgot-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },

  {
    key: "management.inventory",
    path: `${APP_PREFIX_PATH}/management/inventory`,
    component: React.lazy(() => import("views/app-views/management/inventory")),
  },
  {
    key: "management.purchase",
    path: `${APP_PREFIX_PATH}/management/purchase`,
    component: React.lazy(() => import("views/app-views/management/purchase")),
  },
  {
    key: "management.order",
    path: `${APP_PREFIX_PATH}/management/order`,
    component: React.lazy(() => import("views/app-views/management/order")),
  },
  {
    key: "management.sales",
    path: `${APP_PREFIX_PATH}/management/sales`,
    component: React.lazy(() => import("views/app-views/management/sales")),
  },
  {
    key: "management.expense",
    path: `${APP_PREFIX_PATH}/management/expense`,
    component: React.lazy(() => import("views/app-views/management/expense")),
  },
  {
    key: "admin.user",
    path: `${APP_PREFIX_PATH}/admin/user`,
    component: React.lazy(() => import("views/app-views/admin/user")),
  },
  {
    key: "admin.user",
    path: `${APP_PREFIX_PATH}/admin/user`,
    component: React.lazy(() => import("views/app-views/admin/user")),
  },
  {
    key: "admin.region",
    path: `${APP_PREFIX_PATH}/admin/region`,
    component: React.lazy(() => import("views/app-views/admin/region")),
  },
  {
    key: "admin.customer",
    path: `${APP_PREFIX_PATH}/admin/customer`,
    component: React.lazy(() => import("views/app-views/admin/customer")),
  },
  {
    key: "admin.product",
    path: `${APP_PREFIX_PATH}/admin/product`,
    component: React.lazy(() => import("views/app-views/admin/product")),
  },
  {
    key: "admin.warehouse",
    path: `${APP_PREFIX_PATH}/admin/warehouse`,
    component: React.lazy(() => import("views/app-views/admin/warehouse")),
  },
  {
    key: "admin.brand",
    path: `${APP_PREFIX_PATH}/admin/brand`,
    component: React.lazy(() => import("views/app-views/admin/brand")),
  },
  {
    key: "admin.brand-add",
    path: `${APP_PREFIX_PATH}/admin/brand/add`,
    component: React.lazy(() => import("views/app-views/admin/brand/add-brand")),
  },
  {
    key: "admin.brand-edit",
    path: `${APP_PREFIX_PATH}/admin/brand/edit`,
    component: React.lazy(() => import("views/app-views/admin/brand/edit-brand")),
  },
  {
    key: "admin.setting",
    path: `${APP_PREFIX_PATH}/admin/setting`,
    component: React.lazy(() => import("views/app-views/admin/setting")),
  },
];
