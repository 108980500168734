import { gql } from "@apollo/client";

export const SESSION_GQL = gql(`
 query Session {
  session {
    currentOrganizationId
    user {
      id
      userName
      firstName
      lastName
      state
      avatarUrl
      role {
        id, 
        name
      }
    }
  }
}
`);
