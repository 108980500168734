import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import { API_BASE_URL } from "configs/AppConfig";

const isProduction = process.env.NODE_ENV === "production";
const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem("auth_token");

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});
const httpLink = new HttpLink({ uri: API_BASE_URL });

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Attachment: {
        fields: {
          assetUrl: {
            merge: true,
          },
          signedUrl: {
            merge: true,
          },
        },
      },
      Brand: {
        fields: {
          myBrand: {
            merge: true,
          },
        },
      },
      Manufacturer: {
        fields: {
          myManufacturer: {
            merge: true,
          },
        },
      },
      Project: {
        fields: {
          myProject: {
            merge: true,
          },
          additionalInfo: {
            merge: true,
          },
        },
      },
      Swimlane: {
        fields: {
          approvalConfig: {
            merge: true,
          },
        },
      },
      Query: {
        fields: {
          brand: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: "Brand",
                id: args?.id,
              });
            },
          },
          manufacturer: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: "Manufacturer",
                id: args?.id,
              });
            },
          },
          project: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: "Project",
                id: args?.id,
              });
            },
          },
          attachment: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: "Attachment",
                id: args?.id,
              });
            },
          },
          user: {
            read(_, { args, toReference }) {
              return toReference({
                __typename: "User",
                id: args?.id,
              });
            },
          },
        },
      },
    },
  }),
  connectToDevTools: !isProduction,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
      variables: {},
    },
    query: {
      fetchPolicy: "network-only",
      variables: {
      },
    },
    mutate: { variables: {} },
  },
});

export default apolloClient;
