import { gql } from "@apollo/client";

export const GET_BRANDS_GQL = gql(`
 query Brands( $organizationId: ID!) {
  brands(organizationId: $organizationId) {
    results {
      id
      name
      avatarUrl
      createdAt
      updatedAt
    }
    next
    prev
    last
    first
    totalCount
  }
}
`);

export const CREATE_BRAND = gql(`
  mutation CreateBrand($organizationId: ID!, $createBrandInput: BrandCreateInput!) {
  createBrand(organizationId: $organizationId, input: $createBrandInput) {
    id, 
    name, 
  }
}
`);

export const UPDATE_BRAND = gql(`

  mutation UpdateBrand($brandId: ID!, $updateBrandInput: BrandUpdateInput!) {
  updateBrand(id: $brandId, input: $updateBrandInput) {
    id, 
    name, 
    avatarUrl,
  }
}


`);

export const DELETE_BRAND = gql(`
mutation DeleteBrand($brandId: ID!) {
  deleteBrand(id: $brandId)
}
`);
